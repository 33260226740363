import react from "react";
import "./footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

interface FooterProps {
    setMobileSideNavigationCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Footer = ({ setMobileSideNavigationCanvasShow }: FooterProps) => {
    return (
        <>
            <div id="main-footer" className="footer navigation-footer sticky-md-bottom">
                <footer className="footer position-relative">
                    <div className="container">
                        <div className="border-top py-4">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <span className="small">
                                        <span id="copyright">{new Date().getFullYear()}</span>
                                        <span className="ms-2">TopShelfNutra. All rights reserved.</span>
                                        {/* <FontAwesomeIcon className="mx-2 fs-5 cursor-pointer" icon={faInstagram} />
                                        <FontAwesomeIcon className="mx-2 fs-5 cursor-pointer" icon={faYoutube} />
                                        <FontAwesomeIcon className="mx-2 fs-5 cursor-pointer" icon={faFacebook} /> */}
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="d-flex flex-column flex-md-row">
                                        <span>Support: (801) 980-3391</span>
                                        <span className="mt-2 mt-md-0 ms-md-2">
                                            {" "}
                                            Email:{" "}
                                            <a href="mailto:support@topshelfnutra.com" className="text-decoration-none text-white">
                                                support@topshelfnutra.com
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <FontAwesomeIcon
                            onClick={() => setMobileSideNavigationCanvasShow(true)}
                            className="footer-mobile d-block d-lg-none fs-2 cursor-pointer"
                            icon={faBars}
                        />
                    </div>
                </footer>
            </div>
        </>
    );
};
