import react, { useState } from "react";
import "./terms-services-off-canvas.scss";
import { OffCanvasWrapper } from "../OffCanvasWrapper/OffCanvasWrapper";

interface TermsConditionsOffCanvasProps {
    termsConditionsOffCanvasShow: boolean;
    setTermsConditionsOffCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TermsConditionsOffCanvas = ({ termsConditionsOffCanvasShow, setTermsConditionsOffCanvasShow }: TermsConditionsOffCanvasProps) => {
    let data = `
    <p style="margin-left:0.45pt; margin-bottom:12.9pt; text-indent:0pt; text-align:center; line-height:108%">
        <strong><span style="line-height:108%; font-size:10pt; ">As of June 1, 2024</span></strong>&#xa0; 
    </p>
    <p style="margin-right:0.15pt; margin-bottom:13.25pt">
        The Terms and Conditions of TopShelfNutra contained herein were established to explain and define the rights and responsibilities of TopShelfNutra and its Independent Customers, Members, or Affiliates.&#xa0; 
    </p>
    <p style="margin-right:0.15pt; margin-bottom:13.25pt">
        While the majority of these Terms and Conditions are focused on being an Affiliate in TopShelfNutra, they apply to everyone, including Customers and Members as well.&#xa0; 
    </p>
    <p style="margin-right:0.15pt; margin-bottom:13.25pt">
        Each independent Customer, Member, or Affiliate agrees, without reservation, to all the terms and conditions contained herein and holds TopShelfNutra free from any and all liability that may result from this agreement between the Independent Representative and TopShelfNutra.&#xa0; 
    </p>
    <h5>
        CODE OF CONDUCT DECLARATION&#xa0; 
    </h5>
    <p style="margin-right:0.15pt">
        TopShelfNutra (subsequently referred to as the “Company”) has established the following code of conduct to guide the appropriate, efficient and ethical operation of the Company. We require our Customers, Members, or Affiliates to abide by the letter and spirit of this code that forms our contract with all registered parties of TopShelfNutra.&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <p style="margin-right:0.15pt">
        1. Customer, Member and Affiliate Obligation.&#xa0; TopShelfNutra Customers, Members, or Affiliates will:&#xa0; 
    </p>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Conduct themselves in an ethical and professional manner;&#xa0; 
        </li>
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Sell the company’s products in accordance with the compensation plan;&#xa0; 
        </li>
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Make it clear that success in the Company’s compensation plan is based on product purchases rather than sponsoring;&#xa0; 
        </li>
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Represent the compensation plan only as prescribed by the Company;&#xa0; 
        </li>
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Be truthful in my representation of the Products and will make no Product claim that is not approved by and/or supported by official Company publications;&#xa0; 
        </li>
        <li style="margin-right:0.15pt; margin-left:47.58pt; text-indent:0pt; padding-left:0.82pt">
            Comply with applicable consumer protection laws and regulations;&#xa0; 
        </li>
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Maintain current and accurate information concerning the address, phone number, email, social security number, method of payment, and any other data on their file.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <p style="margin-right:0.15pt">
        TopShelfNutra Members will not:&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Engage in deceptive, unlawful, or unethical business or recruiting practices;&#xa0; 
        </li>
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Engage in high-pressure selling or recruiting practices;&#xa0; 
        </li>
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Make misleading sales claims or guarantees concerning the company’s products;&#xa0; 
        </li>
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Make misleading claims or guarantees concerning potential earnings;&#xa0; 
        </li>
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Sponsor or enroll minors or persons who are not capable of making an informed decision;&#xa0; 
        </li>
        <li style="margin-right:0.15pt; margin-left:47.58pt; text-indent:0pt; padding-left:0.82pt">
            Conduct business activities in countries other than those approved by TopShelfNutra;&#xa0; 
        </li>
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Purchase Business Volume on another Member or Customer’s account to qualify for any bonus or commission; or&#xa0; 
        </li>
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Seek in any way to violate or circumvent TopShelfNutra policies.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        <strong>&#xa0;</strong> 
    </p>
    <h5>
        Section 1 Becoming a Customers, Members, or Affiliates&#xa0; 
    </h5>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        <strong>&#xa0;</strong> 
    </p>
    <ol style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:21.08pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Age of Majority. To become a Customer, Member, and/or Affiliate, all Applicants must have reached the age of majority, usually eighteen (18) years of age, in the jurisdiction in which they reside.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:21.08pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>A new Customer, Member, and/or Affiliate is authorized by the Company to exercise their rights and operate when he or she joins as a Customer, Member, or Affiliate by submitting the required fields of information on any of the join pages or upgrade pages of the website.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:20.5pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>The right to accept, renew, or deny any Customers, Members, or Affiliates remains solely with the Company.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:20.5pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>A Customer, Member, or Affiliate may be required to provide the Company with proof of residency, work authorizations, and ability to legally conduct business in the country in which they are conducting business.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="5" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:21.08pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Required Purchase. There are no required product purchases to maintain your status as a Customer, Member, or Affiliate&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="6" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:21.67pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Business Entities. If the Customer, Member, or Affiliate is a Business Entity, the Applicant may also be required to provide an Identification Number for the Business Entity, and a Statement of Beneficial Interest, which must include the signature and Identification Number or other personal identification number of every Person having a Beneficial Interest in the Business Entity. To verify the form of the Business Entity, Beneficial Interest holders, and authorized signatories, the Company may require, at any time, the Applicant to submit a copy of its articles of organization, articles of incorporation or other charter documentation.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="7" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:19.92pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Identification Number. For tax reporting (where required) and identification purposes (where permitted by law), the Company requires Applicants to provide the Identification Number or other personal identification number. Failure to provide this number may result in rejection of the Application or cancellation of the position or commissions withheld.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="8" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:20.5pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Inaccurate Information. If the Company determines that a Customer, Member, or Affiliate submitted inaccurate or false information, it may immediately terminate that person or entity or declare the Customer, Member, or Affiliate Agreement null and void from its beginning.&#xa0; 
        </li>
    </ol>
    <p style="margin-right:0.15pt">
        Further, it is the obligation of the Customers, Members, or Affiliates to report to the Company on an ongoing basis any changes which affect the accuracy of the Agreement.&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="9" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:25.17pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Term. The Contract is valid for the period of one (1) year from the Date of Sign-up. Each year after that, the Contract will be automatically renewed by maintaining an active status in TopShelfNutra.&#xa0;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="10" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:22.83pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Non-Exclusive Territory. The authorization of a Customers, Members, or Affiliates to exercise Rights and operate hereunder does not include a grant of an exclusive franchise or territory, nor is a Customer, Member, or Affiliate allowed to make such claims.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <h5>
        Section 2 Obligations of Customers, Members, or Affiliates and Managing status&#xa0; 
    </h5>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        <strong>&#xa0;</strong> 
    </p>
    <ol style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Compliance. A Customers, Members, or Affiliates shall always comply with each of the terms and conditions of the Contract.&#xa0; 
        </li>
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Independent Contractor. A Customer, Member, or Affiliate is an independent contractor and is responsible for his or her own business expenses, decisions, and actions.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:37.25pt; list-style-position:inside">
            <span style="width:21.49pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>A Customers, Members, or Affiliates shall not represent himself or herself as an agent, employee, partner, or joint venture with the Company. A Customer, Member, or Affiliate shall not make purchases or enter into any transactions in the Company’s name.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:37.25pt; list-style-position:inside">
            <span style="width:21.49pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>A Customer’s, Member’s, and Affiliate’s work hours, business expenditures, and business plans are not dictated by the Company. A Customer, Member, or Affiliate shall make no printed or verbal representations which state or imply otherwise.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:37.25pt; list-style-position:inside">
            <span style="width:21.49pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>A Customer, Member, or Affiliate is fully responsible for all of his or her verbal and/or written statements made regarding the Products, services, and the Compensation Plan which are not expressly contained in official Company materials and the Customer, Member, or Affiliate agrees to indemnify the Company against any claims, damages, or other expenses, including attorneys’ fees, arising from any representations or actions made by the Customer, Member, or Affiliate that are outside the scope of the Contract. The provisions of this Section survive the termination of the Contract.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Compliance with Laws. In conducting its Customers, Members, or Affiliates Business, Customers, Members, or Affiliates must comply with all applicable national and local laws, regulations, and ordinances. Customers, Members, or Affiliates shall not violate any laws which apply to unfair competition or business practices, including any law that prohibits the advertising, offer to sell, or sale of Products at less than the Wholesale price of the Products.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Offerings. A Customers, Members, or Affiliates may not offer or promote any non-approved nonCompany plans, incentives, opportunities, or non-approved Sales Tools in conjunction with the promotion of Products.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="5" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Retail Sales. Achieving success as an Affiliate requires time, effort and commitment. There are no guarantees of Commissions, only rewards based upon productivity. A successful Affiliate Business requires regular and repeated Retail Sales of Products by Affiliate. Retail Sales by an Affiliate’s Downline Organization also contributes to the success of an Affiliate Business.&#xa0;&#xa0;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="6" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Negative Statements. A Customer, Member, or Affiliate will make no disparaging, misleading, inaccurate, or unfair statements, representations, claims, or comparisons with regard to:&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:20.5pt; text-indent:36pt; list-style-position:inside">
            <span style="width:26.74pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>the Company, its Products, its commercial activities, or its Customers, Members, or Affiliatess; or&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:20.5pt; text-indent:36pt; list-style-position:inside">
            <span style="width:26.74pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>other companies, including competitors, their services, products or commercial activities.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:72.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <p style="margin-right:0.15pt; margin-left:73.25pt">
        I. Unethical Activity. A Customer, Member, or Affiliate must be ethical and professional at all times when conducting Business. A Customer, Member, or Affiliate will not permit Customers, Members, or Affiliates in his or her Downline Organization to engage in unethical activity. Examples of unethical activities include, but are not limited to, the following:&#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:122.56pt; text-indent:0pt; padding-left:3.79pt">
            Use of another Customer’s, Member’s, and Affiliate’s credit card without express written permission;&#xa0; 
        </li>
        <li style="margin-right:0.15pt; margin-left:122.56pt; text-indent:0pt; padding-left:3.79pt">
            Unauthorized use of any Company Confidential Information;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:108.8pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:122.56pt; text-indent:0pt; padding-left:3.79pt">
            Cross-Company Recruiting (including aiding and abetting another to&#xa0; 
        </li>
    </ol>
    <p style="margin-right:0.15pt; margin-left:109.3pt">
        Cross-Company Recruit);&#xa0; 
    </p>
    <p style="margin-left:108.8pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:122.56pt; text-indent:0pt; padding-left:3.79pt">
            Making unapproved claims about the Product;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:108.8pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="5" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:122.56pt; text-indent:0pt; padding-left:3.79pt">
            Making income claims about the Affiliate Business which are not compliant with the provisions of the Terms and Conditions;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:108.8pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="6" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:122.56pt; text-indent:0pt; padding-left:3.79pt">
            Making false statements or misrepresentation of any kind, including but not limited to: untruthful or misleading representations or sales offers relating to the quality, availability, grade, price, terms of payment, refund rights, guarantees, or performance of Products;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="7" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:122.56pt; text-indent:0pt; padding-left:3.79pt">
            Personal conduct that discredits the Company and/or its Customers, Members, or Affiliates;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:108.8pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="8" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:122.56pt; text-indent:0pt; padding-left:3.79pt">
            Violating the laws and regulations pertaining to the Affiliate Business;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:108.8pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="9" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:122.56pt; text-indent:0pt; padding-left:3.79pt">
            Failing to meet Customer, Member, or Affiliate responsibilities;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:108.8pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="10" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:126.35pt; text-indent:0pt">
            Violating the Code of Ethics; or&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="11" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:126.35pt; text-indent:0pt">
            Violating the Terms and Conditions.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:108.8pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="7" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Resolving Disputes. A Customer, Member, or Affiliate must conduct all activity in the best interests of the Company.&#xa0; Upline leaders shall use their best efforts to resolve disputes in their Downline Organizations. Any personal disputes between Customers, Members, or Affiliates must be resolved quickly, privately, and in the best interests of the Company.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="8" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            No Claims of Unique Relationship. A Customer, Member, or Affiliate may not allege or imply that he or she has a unique relationship with, advantage with, or access to the Company executives or employees that other Customers, Members, or Affiliates do not have.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="9" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:10.83pt; text-indent:0pt; padding-left:3.12pt">
            Detrimental Conduct. If any conduct by a Customer, Members, and Affiliate is determined by the&#xa0; 
        </li>
    </ol>
    <p style="margin-right:0.15pt">
        Company to be injurious, disruptive, or harmful to the Company or to other Customers, Members, or Affiliates, the Company may take appropriate action against a Customer, Member, and/or Affiliate as the company deems necessary.&#xa0;&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="10" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.17pt; text-indent:0pt; padding-left:0.78pt">
            No Reliance. A Customer, Member, or Affiliate may not rely on the Company to provide legal, tax, financial, or other professional advice, nor may it rely on any such advice if given.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="11" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Insurance. The Company encourages its Customers, Members, or Affiliates to consult with an attorney regarding the extent of their personal legal liability with respect to their independent businesses.&#xa0; 
        </li>
        <li style="margin-right:0.15pt; margin-left:13.76pt; text-indent:0pt; padding-left:0.19pt">
            Privacy of Customer’s, Member’s, and Affiliate’s Information.&#xa0; Customers, Members, or Affiliates authorize the Company to disclose its contact information to the Affiliate’s Downline Organization.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="13" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Notification of Adverse Action. A Customer, Member, or Affiliate shall immediately notify the&#xa0; 
        </li>
    </ol>
    <p style="margin-right:0.15pt">
        Company in writing of any potential or actual legal claims from third parties against the Customer,&#xa0; 
    </p>
    <p style="margin-right:0.15pt">
        Member, or Affiliate arising from, or associated with, the Customer’s, Member’s, and Affiliate’s&#xa0; 
    </p>
    <p style="margin-right:0.15pt">
        Business or the Downline Organization that may adversely affect the Company. After notifying the Company, the Company may take any action necessary to protect itself, including controlling any litigation or settlement of the legal claims. If the Company takes action in the matter, the Customers, Members, or Affiliates shall not interfere or participate in the matter.&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="14" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Release for use of Photo, Audio, or Video Image, and/or testimonial Endorsement.&#xa0; The Company may take photos, audio or video recordings, or written or verbal statements of a Customer, Member, or Affiliate at Company events or may request the same directly from a Customer, 
        </li>
    </ol>
    <p style="margin-right:0.15pt; margin-left:15.2pt">
        Member, or Affiliate. The Customer, Member, or Affiliate agrees to and hereby grants the Company the absolute and irrevocable right and permission, to use, re-use, broadcast, rebroadcast, publish, or republish any such photo, audio, video, or endorsement, in all or in part, individually or in conjunction with any other photograph or video, or any other endorsement, in any current or future medium and for any purpose whatsoever, including (but not by way of limitation) marketing, advertising, promotion, and/or publicity; and to copyright such photograph and/or video, in the original or as republished, in the name of the Company, or in any other name. Regardless of any other agreements or contracts the Customer, Member, or Affiliate may have with any other entity, the Customer, Member, or Affiliate agrees that any use by the Company as set forth in this Section shall be royalty free, is a work made for hire, and is not subject to any other claim. The Customer, Member, or Affiliate agrees to defend and indemnify the Company against any claims by any other party arising out of the Company's use of the rights granted herein. The Customer, Member, or Affiliate confirms that the information he or she may give as a testimonial endorsement, or as represented in a photograph, video or audio is true and accurate to the best of his or her knowledge. The Customer, Member, or Affiliate waives any right he or she may have to inspect or approve the finished or unfinished product(s), the advertising copy, printed, recorded, photographic or video matter which may be used in connection with it or any use that may be made of it.&#xa0; 
    </p>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="15" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Conducting the Customer, Member, or Affiliate Business Internationally. A Customer, Member, or 
        </li>
    </ol>
    <p style="margin-right:0.15pt; margin-left:15.2pt">
        Affiliate has the right to operate in any Authorized Country where the Customer, Member, or 
    </p>
    <p style="margin-right:0.15pt; margin-left:15.2pt">
        Affiliate may lawfully conduct the Customer, Member, or Affiliate Business. It is a Customer, Member, or Affiliate’s responsibility to comply with all national and local laws, ordinances, and regulations when conducting Customer, Member, or Affiliate Business in any Authorized Country.&#xa0;&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:32.51pt; text-indent:0pt; padding-left:7.14pt">
            Only with the Company’s approval, a Customer, Member, or Affiliate may attempt to secure approval, licensing, distribution and/or registration for products or business practices, trademarks, trade names, or internet domain names; or establish any kind of business in international countries and markets on behalf of the Company.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:32.51pt; text-indent:0pt; padding-left:7.14pt">
            A Customer, Member, or Affiliate may not sell, distribute, license, or register products or business practices, use trademarks, trade names or internet domain names in any country without approval of the company.&#xa0;&#xa0;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:32.51pt; text-indent:0pt; padding-left:7.14pt">
            A list of countries where business is approved will be available on the website.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <h5>
        Section 3 Compensation&#xa0; 
    </h5>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <p style="margin-right:0.15pt; margin-bottom:13.5pt">
        TopShelfNutra Customers, Members, or Affiliates may participate in our compensation program as outlined on the Pay Plan page of the website.&#xa0; 
    </p>
    <p style="margin-right:0.15pt">
        Commissions are paid as outlined on the Pay Plan page of the website. All Customers, Members, or&#xa0; 
    </p>
    <p style="margin-right:0.15pt; margin-bottom:13.25pt">
        Affiliates understand BEFORE become joining or purchasing any product that there are NO GUARANTEED EARNINGS. Customers, Members, or Affiliates understand that any and all content on the website is not to reflect earnings, but to be used only for demonstration purposes and as a representation of company growth and does not mean anything in terms of commissions.&#xa0; 
    </p>
    <p style="margin-right:0.15pt; margin-bottom:13.25pt">
        Customers, Members, or Affiliates should not participate in TopShelfNutra under the expectation of earning income without referring new Customers, Members, or Affiliates and Customers. Neither TopShelfNutra, nor its Customers, Members, or Affiliates can guarantee "spillover" or earnings simply by becoming a Customer, Member, or Affiliate. TopShelfNutra Customers, Members, or Affiliates should not participate in TopShelfNutra opportunity if they are not planning on sharing the products with others.&#xa0; 
    </p>
    <p style="margin-right:0.15pt; margin-bottom:13.5pt">
        Most Customers, Members, or Affiliates earn less money each month in the compensation program than they are paying for their products. Although it is possible, TopShelfNutra Customers, Members, or Affiliates should NOT expect to make a profit simply by becoming a Customer, Member, or Affiliate as it is very possible that will not occur.&#xa0; 
    </p>
    <p style="margin-right:0.15pt; margin-bottom:13.25pt">
        TopShelfNutra cannot guarantee that Customers, Members, or Affiliates earn a profit by implementing the training materials provided. The products are for educational purposes only.&#xa0; 
    </p>
    <p style="margin-right:0.15pt; margin-bottom:13.25pt">
        No Customer, Member, or Affiliate should spend money that they cannot afford to lose to purchase products, advertising materials, or anything else related to TopShelfNutra. It is possible that you will NOT earn any income as a Customer, Member, or Affiliate of TopShelfNutra.&#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:21.08pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Earnings. Commissions are paid to Customers, Members, or Affiliates who qualify pursuant to the Compensation Plan and who are in compliance with the Contract. A Customer, Member, or Affiliate’s success is only achieved through the regular and repeated purchase of products and the regular and repeated product sales by its Downline Organization.&#xa0; As the success of any Customer, Member, or Affiliate depends largely on the personal efforts of that Customer, Member, or Affiliate, the Company does not guarantee any level of profit or success, nor does it guarantee a Customer, Member, or Affiliate a specific income. A Customer, Member, or Affiliate does not receive compensation for sponsoring or recruiting other Customers, Members, or Affiliates. The only way to earn Commissions is through the sale of Products.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:21.08pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Payment. The Company will pay Commissions to qualified Affiliates on Product orders and Memberships which:&#xa0; 
        </li>
    </ol>
    <p style="margin-right:0.15pt">
        (i) are received by the Company before the end of the Commission period, and (ii) have been fully paid with appropriate payment.&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <p style="margin-right:0.15pt; margin-left:36.75pt; text-indent:-18pt">
        1. Commissions are paid in the name of the Person or Business Entity listed in Payment Option link on the My Earnings section of the website.&#xa0; When no payment option is selected, commissions will be held until selected.&#xa0; 
    </p>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:20.5pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Commission Payments. In the event that a Commission payment does not arrive to an Affiliate and has been returned to the company, the company will resend the commission at no additional charge.&#xa0;&#xa0;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:20.5pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Minimum Payment Amount. Affiliates will select how they choose to get paid on the Payment Options page of the website.&#xa0; The minimum amount for payment of commission payments is two hundred dollars ($200 USD).&#xa0; Commissions less than the minimum for a pay period will accumulate until they equal or exceed the minimum payment amount.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="5" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:21.08pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Returned or Unpaid Payments. The Company makes every effort to ensure that an Affiliate receives its commission payments. However, if a commission payment is unpaid due to insufficient information or other reasons beyond the control of the Company, the payment will be held for the benefit of the Customer, Member, or Affiliate for 90 days.&#xa0; After such 90 days, a monthly maintenance charge of ten dollars ($10 USD or equivalent local currency) will be deducted from the Affiliate’s payment.&#xa0;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="6" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:21.67pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>No Manipulation. Manipulation of the Compensation Plan is not permitted and may result in disciplinary action. Manipulation of the Compensation Plan includes, but is not limited to, an Affiliate purchasing, to qualify for various Ranks or Commissions, large quantities of Product that are not sold through the direct marketing channel, placing orders in his/her Downline Organization, and any other actions that may violate state, federal or foreign anti-pyramid scheme laws. Such manipulations may, in the discretion of the Company, result in the suspension of Commissions and termination of the Affiliate.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="7" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:19.92pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Deductions and Offsets. Affiliates authorize the Company to deduct fees from its Commissions as outlined on the Payment Option page and/or as deemed appropriate at the sole discretion of the Company.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <h5>
        Section 4 Ordering Company Products&#xa0; 
    </h5>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        <strong>&#xa0;</strong> 
    </p>
    <ol style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Inventory. As the Company imposes no specific minimum inventory requirement on its Customers, Members, or Affiliates, a Customer, Member, or Affiliate must use its own judgment to determine the amount of inventory it will need to sustain its projected personal use.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Ordering. Products can be ordered by Internet.&#xa0; Unless otherwise arranged, all membership payments and product purchases will be done from the company website.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Back Orders. If the Company is temporarily out of stock on ordered Product, a Customer, Member, or Affiliate will receive a “back order” notice with his or her shipment. Back orders are filled first as new inventory arrives. Volume on back orders is credited to the month in which payment for the original order was received by the Company.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Autoship Program .&#xa0; 
        </li>
    </ol>
    <p style="margin-right:0.15pt">
        1. A Customer, Member, or Affiliate may choose to participate in the Autoship Program if available on the website. When instituting Autoship program at the time of enrollment, the Customer, Member, or Affiliate Agreement serves as confirmation for the setup. An Autoship account will be charged at a set time during the month, and the Product will be shipped at a set time thereafter. The Customer, Member, or Affiliate may obtain tracking numbers from the Company after the Product is shipped for countries where tracking numbers are available. The scheduled dates for Autoship processing, account charges, shipping or account changes may be selected in the Autoship section of the Order Product page on the website.&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:22.24pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>There is no limit as to how many Autoship orders a Customer, Member, or Affiliate may have set up or how many bottles of product are included in each Autoship order.&#xa0; All Autoship orders will be shipped to the Shipping Address in the Personal Info section of the website.&#xa0; If an Autoship order fails for billing reasons, the company will attempt to bill the payment method on file for each of the next 10 days until the order goes through.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="5" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:22.24pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>To change or terminate one’s Autoship order, the Customer, Member, or Affiliate may either select it on their Autoship page of the Product Order section of the website, or contact customer support.&#xa0; Autoship orders may be cancelled at any time prior to the order being billed.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:18.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="8" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Sales Tax, GST, VAT.&#xa0; 
            <ol style="margin-right:0pt; margin-left:0pt; padding-left:0pt">
                <li style="margin-right:0.15pt; margin-left:37.06pt; padding-left:22.24pt">
                    Sales tax is collected on the Product’s suggested retail price and is calculated using the applicable rates for the location to where the product is shipped. The Company will collect and remit sales tax to the proper taxing authority. In those jurisdictions where a Customer, Member, or Affiliate may and has registered as a withholding agent through a local sales tax agency and submitted a “Sales and Use Tax Exemption Certificate” or equivalent document to the Company, the&#xa0;               collection of sales tax will be the responsibility of the Customer, Member, or Affiliate. It is the responsibility of the Customer, Member, or Affiliate to provide an updated copy of its certification for exemption from sales tax each year.&#xa0; 
                </li>
            </ol>
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:50.51pt; text-indent:0pt; padding-left:22.24pt">
            In all other jurisdictions, GST, VAT, or other applicable transaction tax is based on the purchase price. The Company will provide its GST or VAT number and proper invoicing, which may include electronic invoicing, where permitted by law. The Company does not include GST or VAT in commission payments. Customers, Members, or Affiliates who are GST or VAT registered and are required to collect and remit GST or VAT on their services may send a valid GST or VAT invoice to the Company to charge them for GST or VAT on commission income.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="9" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:10.83pt; text-indent:0pt; padding-left:2.62pt">
            Returns, Refunds, and Exchanges. The Company will refund the purchase price of Product or exchange it pursuant to the following.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:50.51pt; text-indent:0pt; padding-left:22.24pt">
            If the Customer, Member, or Affiliate (and/or his or her Customer who ordered directly from the Company) is not completely satisfied with the First Product Purchase, he or she may send to the Company the unused portion of the First Product Purchase within thirty (30) days of the original purchase date and the Company will refund 100% of the purchase price (less shipping and handling). If the First Product Purchase is returned after the thirty (30) day period, no refund will be given.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.15pt; text-indent:0pt; line-height:108%">
        &#xa0; <span style="width:29.42pt; color:#000000; display:inline-block">&#xa0;</span>&#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:50.51pt; text-indent:0pt; padding-left:22.24pt">
            Any Commissions paid to the Customer, Member, or Affiliate and his or her Upline for the Product returned by the Customer, Member, or Affiliate or Customer will be deducted from the respective Upline Customers, Members, or Affiliates’ accounts or withheld from present or future Commission payments. A Customer, Member, or Affiliate agrees that he or she will not rely on existing Downline Organization Volume at the close of a Commissions period, as returns may cause changes to his or her Title, Rank and/or Commissions payout.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:50.51pt; text-indent:0pt; padding-left:22.24pt">
            All shipping or courier costs for the return of Product will be borne solely by the 
        </li>
    </ol>
    <p style="margin-right:0.15pt; margin-left:37.75pt">
        TopShelfNutra unless otherwise prohibited by law. Any damage or loss that 
    </p>
    <p style="margin-right:0.15pt; margin-left:37.75pt">
        occurs to returned Product during shipping will be the responsibility of the Customer, Member, or&#xa0; 
    </p>
    <p style="margin-right:0.15pt; margin-left:37.25pt">
        Affiliate.&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:50.51pt; text-indent:0pt; padding-left:22.24pt">
            The Company will exchange Product if the Product is damaged in shipment, incorrectly sent due to a Company error, or of substandard quality. However, when an exchange is not feasible, the Company will refund the amount of the returned Product. If Product is damaged or defective, a Customer, Member, or Affiliate should contact the Company within ten (10) days of receipt of the order.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <h5>
        Section 5 Marketing the Product and Opportunity&#xa0; 
    </h5>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        <strong>&#xa0;</strong> 
    </p>
    <ol style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Use of Sales Tools. A Customer, Member, or Affiliate may use only Sales Tools approved by the Company for an Authorized Country. The Customer, Member, or Affiliate agrees that if it uses a fulfillment house or other third party to sell or distribute Sales Tools, the Customer, Member, or Affiliate will enter into a non-disclosure agreement (to be provided by the Company) with the fulfillment house or third party to ensure that all Customer, Member, or Affiliate and Customer information is protected from disclosure and remains the sole property of the Company.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Approval of Sales Tools. A Customer, Member, or Affiliate must submit all Sales Tools to the Company for approval prior to use. The Company has complete discretion whether to approve or reject a proposed Sales Tool. The approval process generally requires a minimum of three (3) weeks to complete. To comply with changing laws and regulations, the Company may rescind its prior approval of a Sales Tool, and may require the Customer, Member, or Affiliate to remove from the market at its own cost and obligation a previously approved Sales Tool. If approved, the Company will issue an email to the Customer, Member, or Affiliate confirming approval of said Sales Tools.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Product Claims. The only claims and representations Customers, Members, or Affiliates may make regarding Products are those found in the literature distributed by the Company. Any thirdparty material used for Customer, Member, or Affiliate Business must comply with all federal and local laws and regulations.&#xa0; A Customer, Member, or Affiliate may not make any express or implied health or medical claims of any kind relating to any Product except for those claims, if any, that are published in Company literature approved for the country in which the claims are presented. Under no circumstances may a Customer, Member, or Affiliate prescribe any Product as suitable for a particular ailment. No claims may be made as to therapeutic or curative properties of any Product offered by the Company.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            No Altering. Customers, Members, or Affiliates shall not re-label, alter or repackage any Products.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="5" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            No Endorsement Claims. No Customer, Member, or Affiliate may imply that the promotion, operation, or organization of the Company has been approved, sanctioned, or endorsed by any governmental regulatory authority unless noted on company website.&#xa0;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="6" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Income Claims Prohibition. A Customer, Member, or Affiliate is prohibited from making false, misleading, or unrepresentative claims regarding earning potential. If a Customer, Member, or Affiliate does make an income claim, it must be based on actual earnings and the Company's current Annual Average Income Disclosure, posted on the Company’s website, must be presented concurrent with the income claim.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="7" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Use of Trademarks and Copyrights.&#xa0; 
            <ol style="margin-right:0pt; margin-left:0pt; padding-left:0pt">
                <li style="margin-right:0.15pt; margin-left:34.45pt">
                    The Company may license the use of its trademarks to Customers, Members, or Affiliates, subject to the limitations herein and subject to the limitations in any licensing agreement. A licensing agreement may be obtained by emailing customer support.&#xa0; 
                </li>
            </ol>
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Customers, Members, or Affiliates may not use any of the Company’s current or after acquired trademarks or any confusingly similar variations of its marks, in a manner that is likely to cause confusion, mistake, or deception as to the source of the Products or services advertised.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Except as indicated herein, a Customer, Member, or Affiliate may not use the Company’s trademarks or any confusingly similar variation of its trademarks (e.g., CupAHealth, TopShelfNutra, etc.), in a business name, e-mail address, Internet domain name or sub-domain name, URL, telephone number, or in any other address or title. A Customer, Member, or Affiliate may use the Company’s trademarks in a URL, Internet domain or subdomain name provided that the Customer, Member, or Affiliate has entered into a licensing agreement for a Company Licensed Website. The Customer, Member, or Affiliate agrees to comply with the terms of such licensing agreement and hereby acknowledges that the Company owns, and shall continue to own, all rights in and to the Company’s trademarks in such URL, Internet domain or sub-domain name and that the Company has the right to revoke such use of the Company’s trademarks for any reason and at any time. The&#xa0; 
        </li>
    </ol>
    <p style="margin-right:0.15pt; margin-left:37.25pt">
        Customer, Member, or Affiliate further agrees that the Company has the right to acquire such URL at any time by paying the nominal registration fee to the Customer, Member, or Affiliate and Customer, Member, or Affiliate agrees to transfer such URL to the Company and take any other necessary steps requested by the Company to effectuate such transfer.&#xa0; 
    </p>
    <p style="margin-left:36.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            The Customer, Member, or Affiliate agrees to immediately re-assign to the Company any registration of the&#xa0; 
        </li>
    </ol>
    <p style="margin-right:0.15pt; margin-left:37.25pt">
        Company names, trade names, trademarks, or Internet domain names registered or reserved in violation of this policy. The provisions of this Section survive the termination of the Contract.&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="5" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Customers, Members, or Affiliates may not use the Company’s trademarks on nonapproved Sales Tools.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="6" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            The Company, in its sole discretion, will determine whether a variation of its trademark is confusingly similar.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="7" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Customers, Members, or Affiliates shall not use the Company’s marks in countries where the use of such marks is prohibited.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="8" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            A Customer, Member, or Affiliate must not use the name, logos, trademarks or other references to the Company’s business or manufacturing partners in any Sales Tool, correspondence, or any form of advertising.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0.55pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="9" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            The Company’s literature and media are copyrighted by the Company and may not be duplicated.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="8" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Use of “Independent Customer, Member, or Affiliate” in Advertising. If a Customer, Member, or Affiliate selects a business title, the title must clearly state that the Customer, Member, or Affiliate is a “TopShelfNutra Independent Customer, Member, or Affiliate.” A Customer, Member, or Affiliate’s title may not imply that the Customer, Member, or Affiliate is an employee or agent of the Company.&#xa0; 
        </li>
    </ol>
    <p style="margin-right:0.15pt">
        Each time the Company’s logo or name is used in writing and in relation to the Customer, Member, or Affiliate, the Customer, Member, or Affiliate must identify itself as a “TopShelfNutra Independent Customer, Member, or Affiliate.”&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="9" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:10.83pt; text-indent:0pt; padding-left:3.12pt">
            Methods of Advertising. Customers, Members, or Affiliates may advertise using the following means:&#xa0; 
            <ol style="margin-right:0pt; margin-left:0pt; padding-left:0pt">
                <li style="margin-right:0.15pt; margin-left:34.45pt">
                    Newspaper: A Customer, Member, or Affiliate may place a generic business opportunity advertisement in the classified section of a local newspaper, provided the advertisement conforms to all applicable laws and regulations.&#xa0; 
                </li>
            </ol>
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Phone Directory: Any Customer, Member, or Affiliate may place a text listing of its name in the white or yellow pages of a telephone directory followed by “TopShelfNutra Independent Customer, Member, or Affiliate.” Graphical and display ads in telephone directories are prohibited.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Electronic Mail Advertisements: All advertisements sent via e-mail, telephone, or facsimile must comply with all anti-spamming laws for the state or country where the intended recipient resides. The Customer, Member, or Affiliate is under obligation to research and comply with all laws concerning unsolicited commercial e-mail.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Television and Radio: Television and radio advertising requires prior written approval from the Company. Requests should be submitted through customer support.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="5" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Celebrity Endorsement: A Customer, Member, or Affiliate may use a celebrity endorsement with written approval from the Company and the specific, prior, written approval of the endorsing celebrity for each use of the celebrity's name.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="6" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Fairs, Swap Meets, Etc.: A Customer, Member, or Affiliate may sell or promote Products at bazaars, flea markets, fairs, swap meets, tradeshows or other similar gatherings only at a price of no less than the Customer, Member, or Affiliate price of the products listed on the website.&#xa0;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="7" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Internet Auction Sites: A Customer, Member, or Affiliate may sell or facilitate the sale of Product on Internet websites where an auction is the mode of selling or buying (e.g., eBay), so long as the product has a minimum reserve selling price of no less than the Customer, Member, or Affiliate price of the product on the website. A Customer, Member, or Affiliate may not use a third party to place Product on auction websites or sell Product to a third party if the Customer, Member, or Affiliate knows, or has reason to know, that such Product will be sold on auction websites for less than the Customer, Member, or Affiliate price of the product. The provisions of this Section survive the termination of the Contract.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="10" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.17pt; text-indent:0pt; padding-left:0.78pt">
            Advertising at Company Sponsored Events. At Company-sponsored events, Customers, Members, or Affiliates may not, unless specifically authorized in writing by the Company, advertise, sell, or promote non-Company products or services, including, but not limited to: (i) the promotion of non-Company events, systems or materials, (ii) organized person to person solicitations, (iii) distribution of flyers, DVDs or other materials, or (iv) the use of any other form of promotion deemed inappropriate by the Company.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="11" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Internet Advertising. Subject to the provisions herein, Customers, Members, or Affiliates may use only a Company Licensed Website to promote Products or the business opportunity over the Internet. Promoting Products or the business opportunity through an unlicensed Internet website is strictly prohibited. Customers, Members, or Affiliates that wish to operate a Company Licensed Website must meet the following criteria:&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            A Customer, Member, or Affiliate may not enter into a website licensing agreement until it has completed a website training course given by the Company.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            All licensed websites must first be reviewed and approved by the Company as Sales Tools. Licensed websites must be Company-specific and may not advertise, promote, or link to any other product or opportunity&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Customers, Members, or Affiliates may not use any key words or meta tags to advertise any licensed website on the Internet if the search words or meta tags explicitly or implicitly present illegal or unsubstantiated health or income claims.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            The Company may revoke the license for any previously approved website at any time and for any reason, including changes to federal and local laws and regulations.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="5" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Customers, Members, or Affiliates may promote the business opportunity and Products on social networking sites such as “Facebook” and “Twitter;” video sites such as “YouTube” and “Google Video;” and blogging sites such as “Wordpress” and “Blogger” (collectively “Social Media Sites”), provided the following conditions are met:&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
        <li style="margin-right:0.15pt; margin-left:86.51pt; text-indent:0pt; padding-left:4.24pt">
            All text, audio and video postings do not contain Product or income claims. For 
        </li>
    </ol>
    <p style="margin-right:3.1pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:right; line-height:108%">
        Product information, Customers, Members, or Affiliates may refer viewers to their&#xa0; 
    </p>
    <p style="margin-right:0.15pt; margin-left:73.25pt">
        TopShelfNutra replicated website, the Company website, or a Company Licensed Website;&#xa0; 
    </p>
    <p style="margin-left:72.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
        <li style="margin-right:0.15pt; margin-left:86.51pt; text-indent:0pt; padding-left:4.24pt">
            Videos posted to Social Media Sites must show the text “TopShelfNutra Independent Customer, Member, or Affiliate” for the entirety of the video;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
        <li style="margin-right:0.15pt; margin-left:85.92pt; text-indent:0pt; padding-left:4.83pt">
            The Company may monitor the Social Media Sites for compliance with the Contract and Customer, Member, or Affiliate agrees to immediately remove or modify the Social Media Sites upon the Company’s request to comply with the Contract.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:72.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="12" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.76pt; text-indent:0pt; padding-left:0.19pt">
            Advertising and Selling Price of Products on the Internet. Customer, Member, or Affiliate acknowledges and agrees that the advertising and selling of all Products on the Internet may only be done on a Company Licensed Website and the advertising and selling price of all Products on such website (i) if sold to an Applicant, must not be lower than the Customer, Member, or Affiliate price of the Products plus reasonable shipping and the amount the Company charges for taxes, handling.&#xa0; In connection with this Section, the Customer, Member, or Affiliate also agrees that all advertising regarding the price of Products will be truthful and will not contain misleading statements (e.g. "lowest price available" which infers that a Customer, Member, or Affiliate is able to sell the Products at a price lower than other Customers, Members, or Affiliates, etc.). Customer, Member, or Affiliate acknowledges and agrees that he or she shall not advertise or sell any Products on the Internet which were purchased from another Customer, Member, or Affiliate. Any violation of this Section by a Customer, Member, or Affiliate shall constitute a breach of the Contract and will be subject to termination of Customers, Members, or Affiliateship.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.8pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="13" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Mass Communications. For purposes of this Section, “Mass Communications” are defined as communications intended to reach twenty (20) or more Customers, Members, or Affiliates in the sender’s Downline Organization or at least three Customers, Members, or Affiliates who are crossline, within a seven (7) day period. The following rules apply to all Mass Communications issued by a Customer, Member, or Affiliate:&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            Customers, Members, or Affiliates targeted to receive the Mass Communications must have knowingly “opted in” to hear or receive the Mass Communication&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
        <li style="margin-right:0.15pt; margin-left:86.51pt; text-indent:0pt; padding-left:4.24pt">
            through registration (if the Mass Communication will be received at an event or webinar); and/or&#xa0; 
        </li>
    </ol>
    <p style="margin-left:90.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
        <li style="margin-right:0.15pt; margin-left:86.51pt; text-indent:0pt; padding-left:4.24pt">
            through an affirmative request if the Mass Communication is delivered through an email or on a website.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.55pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            If by e-mail, there must be an “opt out” feature prominently displayed in the Mass Communication.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            The Mass Communication must comply with the terms of this Section.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            The following disclaimer shall be prominently positioned in all Mass Communications that promote any particular building method:&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <p style="margin-right:0.15pt; margin-left:37.25pt">
        There are many methods and techniques used successfully for building your TopShelfNutra business. The building method promoted [in/at] this [website/webinar/email/ meeting/] may be different from that which is taught by your upline. Please consult with your upline if they have taught you a different building method or if you have any questions.&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="5" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; margin-bottom:0.25pt; text-indent:0pt; line-height:103%">
            Customer, Member, or Affiliate acknowledges that allowing the Customer, Member, or Affiliate to create databases of Customer, Member, or Affiliate information for Mass&#xa0; 
        </li>
    </ol>
    <p style="margin-right:0.15pt; margin-left:37.25pt">
        Communications, the sale of tools, and for any other purposes constitutes the use of Company Confidential Information, which information is the Company’s trade secrets, and such use can be a substantial financial benefit to the Customer, Member, or Affiliate.&#xa0; 
    </p>
    <p style="margin-right:0.15pt; margin-left:37.25pt">
        Customer, Member, or Affiliate acknowledges that he or she is subject to the CrossCompany Recruiting obligations set forth in this agreement and shall survive the termination of the Contract.&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="14" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Lead Distribution. Persons who are outside the Company network often make inquiries to the Company about its Products. If the Company is able to determine that the inquiring Person received the information from a specific Customer, Member, or Affiliate or that there is a particular Customer, Member, or Affiliate that the Person is acquainted with, every attempt will be made to refer the Person to that Customer, Member, or Affiliate. If an association with a particular Customer, Member, or Affiliate cannot be determined, final judgment with respect to the positioning of leads remains the right of the Company.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="15" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Public Relations Matters. The Company encourages Customers, Members, or Affiliates to use personal media coverage to expand and build their business; however, certain situations require the Customer, Member, or Affiliate to contact the Company. These would include:&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            instances where the story or medium has national potential;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            cases where the story calls for a wider Company/Product perspective; and/or&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:48.4pt; text-indent:0pt">
            when the Customer, Member, or Affiliate is questioned about Company sales figures and/or business strategies.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="16" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.95pt; text-indent:0pt">
            Retail or Service Establishments: A Customer, Member, or Affiliate may sell Products or promote the business opportunity through Retail or Service Establishments as long as i) The display of Independent Customer, Member, or Affiliate information within the premises of a Retail or Service Establishment is clearly indicated, and ii) the product is not sold for an amount less than the Customer, Member, or Affiliate price of the product as shown on the website.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <h5>
        Section 6 Breach of Contract Procedures&#xa0; 
    </h5>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Conditional Obligations. The Company’s obligations to a Customer, Member, or Affiliate are conditioned upon the Customer, Member, or Affiliate’s faithful performance of the terms and conditions of the Contract. The Company, in its sole discretion, will determine if a Customer,&#xa0; Member, or Affiliate is in breach of the Contract and may elect any or all available remedies.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Remedies. In the event of breach, the Company may elect to take no action or to exercise some or all contractual remedies and remedies at law or in equity, including, but not limited to:&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:50.51pt; text-indent:0pt; padding-left:4.24pt">
            Notify the Customer, Member, or Affiliate either in writing or verbally of the breach and providing a notice to cure the breach;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:54.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:50.51pt; text-indent:0pt; padding-left:4.24pt">
            Require from the Customer, Member, or Affiliate additional assurances of future compliance;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:50.51pt; text-indent:0pt; padding-left:4.24pt">
            Withhold or deny recognition and attendant perks;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:50.51pt; text-indent:0pt; padding-left:4.24pt">
            Assess damages and withhold them from commission payments;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="5" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:50.51pt; text-indent:0pt; padding-left:4.24pt">
            Suspend Customer, Member, or Affiliate Rights temporarily or permanently;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="6" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:50.51pt; text-indent:0pt; padding-left:4.24pt">
            Seek injunctive relief;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="7" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:50.51pt; text-indent:0pt; padding-left:4.24pt">
            Terminate the Contract; and&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="8" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:50.51pt; text-indent:0pt; padding-left:4.24pt">
            Seek damages and associated costs.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Reporting Contract Breaches. If a Customer, Member, or Affiliate observes or is aware of another&#xa0; Customer, Member, or Affiliate’s violation of any term or condition of the Contract, the observing Customer, Member, or Affiliate shall submit a written complaint to the Company’s support department through email.&#xa0; Because of the difficulties of investigating and asserting appropriate remedies for stale claims, any complaint for breach of the terms and conditions of the Contract other than CrossCompany Recruiting must be brought to the Company’s attention for review within eighteen (18) months of the start of the alleged violation; Cross-Company Recruiting violations must be brought to the Company’s attention within six (6) months of the alleged violation. Failure to report a violation within that time period may result in the Company not pursuing the allegations in order to prevent the Customer, Member, or Affiliate Business from being disrupted due to stale claims.&#xa0; 
        </li>
    </ol>
    <p style="margin-right:0.15pt">
        However, this policy does not waive the Company’s right to investigate and discipline Customers, Members, or Affiliates found guilty of the stale claims.&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Circumvention of the Contract. The Contract is designed to protect Customers, Members, or Affiliates and the Company from the adverse consequences of their violation. Customers, Members, or Affiliates who intentionally circumvent the Contract to accomplish indirectly what is prohibited directly will be disciplined as if the applicable policy or rule had been broken directly. In such circumstances, all of the available remedies as stated above will be available to the Company. The Contract is not intended to give a Customer, Member, or Affiliate the right to enforce the Contract against another Customer, Member, or Affiliate directly, or to take any legal action against another Customer, Member, or Affiliate.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <h5>
        Section 7 Termination&#xa0; 
    </h5>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <p style="margin-right:0.15pt">
        A. Termination.&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:22.24pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>A Customer, Member, or Affiliate may terminate the Contract by writing a request to support to terminate, by calling customer support, by written mail, or on the Personal Info page of the back office of the website.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:22.24pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>The Company may terminate the Contract if the Customer, Member, or Affiliate violates the terms of the Contract and any amendments thereto.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:22.24pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Upon termination, the Company may in its sole discretion retain the Customers, Members, or Affiliateship or dissolve and remove it from the Compensation plan.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Return of Confidential Information. A Customer, Member, or Affiliate must return all Confidential Information, including any information derived therefrom, over which he or she has direct or indirect control to the Company upon termination or upon demand of the Company. If any such 
        </li>
    </ol>
    <p style="margin-right:0.15pt; margin-left:14.7pt">
        Confidential Information cannot be returned because it is in electronic format, the Customer, Member, or Affiliate shall permanently delete and erase the Confidential Information upon termination or upon demand.&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Buyback. If a Customer, Member, or Affiliate is in breach, the Company reserves the right to stop or delay the buy-back process set forth in this Contract.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Effects of Termination for Breach of Contract.&#xa0; 
            <ol style="margin-right:0pt; margin-left:0pt; padding-left:0pt">
                <li style="margin-right:0.15pt; margin-left:23.8pt; text-indent:-0.5pt; list-style-position:inside">
                    <span style="width:21.49pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>A Customer, Member, or Affiliate whose Contract is terminated by the Company must wait six (6) months before applying for a new Customers, Members, or Affiliateship. During that time, the Customer, Member, or Affiliate can have no Beneficial Interest in any other Customers, Members, or Affiliateship.&#xa0;&#xa0; 
                </li>
            </ol>
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:37.25pt; list-style-position:inside">
            <span style="width:21.49pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Upon termination of the Contract, all of the Customer, Member, or Affiliate’s rights in and to the Customers, Members, or Affiliateship and the Customer, Member, or Affiliate Business are revoked and terminated. In acknowledgement of the damages the Company has likely suffered and/or will suffer as a result of Customer, Member, or Affiliate's breach, including but not limited to, all or any of the following: (i) loss of good will and loss in the value of the Company’s confidential and proprietary information and trade secrets; (ii) loss of a portion of the value of the Company’s business; and (iii) loss of future profits; Customer, Member, or Affiliate consents that any unpaid Commissions may be forfeited to the Company to offset a portion of the damages.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:37.25pt; list-style-position:inside">
            <span style="width:21.49pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>The Company may elect to reorganize the Downline Organization of a Customers, Members, or Affiliateship terminated for breach in a manner that serves the best interests of the Company, Downline Organization and Upline.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:36.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:37.25pt; list-style-position:inside">
            <span style="width:21.49pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Where the Company elects to terminate a Customers, Members, or Affiliateship in which there is more than one Beneficial Interest holder, the following may apply: a. the departing Beneficial Interest holder(s) must relinquish all rights to, and interests in, the Customers,&#xa0; 
        </li>
    </ol>
    <p style="margin-right:0.15pt; margin-left:37.25pt">
        Members, or Affiliateship; b. The Company may not divide or reassign any of the Downline Organization; and c. The Company may not split Commissions between the prior or current Beneficial Interest holders of the Customers, Members, or Affiliateship.&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="5" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Effects of Voluntary Termination by the Customer, Member, or Affiliate.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:22.24pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>The Contract can be voluntarily terminated by a Customer, Member, or Affiliate who is not in breach of the Contract for any reason, at any time, by providing written notice to the Company signed by all Person(s) listed on the Customer, Member, or Affiliate Agreement. The termination is effective on the date the Company receives the written notice.&#xa0; If a Customer, Member, or Affiliate is in breach of the Contract, he or she cannot voluntarily or unilaterally terminate the Contract&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:22.24pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Upon termination of the Contract, all of the Customer, Member, or Affiliate’s rights in and to the Customers, Members, or Affiliateship and the Customer, Member, or Affiliate Business are revoked and terminated.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:22.24pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>A Customer, Member, or Affiliate who voluntarily terminates Customers, Members, or Affiliateship and is not in breach of the Contract may rejoin under a new Customers, Members, or Affiliateship under the same or a new enroller at any time.&#xa0;&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt">
        <li style="margin-right:0.15pt; margin-left:0.5pt; list-style-position:inside">
            <span style="width:22.24pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>A Customer, Member, or Affiliate may not terminate voluntarily if the Customers, Members, or Affiliateship is not in good standing with the Company, as may be evidenced by, but not limited to, any of the following conditions: (i) a temporary Customers, Members, or Affiliateship; (ii) a Customers, Members, or Affiliateship is on hold, suspension or probation; (iii) the Customers, Members, or Affiliateship is under investigation, but no formal discipline has taken place; or (iv) notice of intent to terminate has been sent.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <h5>
        Section 8 Miscellaneous&#xa0; 
    </h5>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Entire Agreement. The Contract contains the entire understanding concerning the subject matter hereof between the Company and the Customer, Member, or Affiliate, and is intended as a final, complete, and exclusive expression of the terms of the parties. This Contract supersedes and replaces all prior negotiations and proposed, but unexecuted agreements, either written or oral. Any prior agreements, promises, negotiations, or representations, either written or oral, relating to the subject matter of this Contract, are of no force or effect. If there is any discrepancy between verbal representations made to the Customer, Member, or Affiliate by any employee or agent of the Company and the terms of the Contract, the express written terms and requirements of the Contract will prevail.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="2" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Headings<strong>. </strong>The section and subsection headings in the Contract are inserted solely as a matter of convenience and for reference, and will not be considered in the construction or interpretation of any provision hereof. Unless the context otherwise specifically requires, all references to sections of the Contract will refer to all subsections thereof.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="3" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Modifications by the Company. The Company reserves the right to make any modifications to the Contract, provided that the modifications are communicated by the Company to the Customer, Member, or Affiliate at least thirty (30) days prior to taking effect. The Company may communicate these modifications by posting any portion of the modified Contract on the Company’s website, or by any other method of communication. The Customer, Member, or Affiliate is deemed to have accepted the modification to the Contract if the Customer, Member, or Affiliate engages in any Customer, Member, or Affiliate Business, renews its Customers, Members, or Affiliateship, or accepts Commissions after the thirty (30) day period is ended.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="4" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Warranties. The Company extends no product warranties, either expressed or implied, beyond those specifically articulated in the Contract. The Company disclaims and excludes all warranties regarding possible infringement of any United States or foreign patent, trademark, trade name, 
        </li>
    </ol>
    <p style="margin-right:0.15pt; margin-left:14.7pt">
        copyright, or trade secret arising from the Customer, Member, or Affiliate’s operations. THE&#xa0; 
    </p>
    <p style="margin-right:0.15pt">
        COMPANY HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,&#xa0; 
    </p>
    <p style="margin-right:17.15pt; margin-left:1pt; margin-bottom:0.25pt; text-indent:-1pt; text-align:justify; line-height:103%">
        WITHOUT LIMITATION, ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY AND NON-INFRINGEMENT. THIS DISCLAIMER OF WARRANTY CONSTITUTES AN ESSENTIAL PART OF THIS AGREEMENT.&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="5" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Waiver. Any waiver by the Company of a Customer, Member, or Affiliate’s breach of a Contract provision must be in writing and will not be construed as a waiver of any subsequent or additional breach by the Customer, Member, or Affiliate. The failure by the Company to exercise any right or privilege under the Contract will not constitute a waiver of that right or privilege.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="6" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Severability. If any term or condition of this Contract is judicially invalidated, prohibited, or otherwise rendered unenforceable in any jurisdiction, it is unenforceable only to the extent of the invalid, prohibited or unenforceable provision in that jurisdiction only, and it will not render unenforceable or invalidate any other provision of the Contract, nor will the Contract be rendered unenforceable or invalidated in another jurisdiction. Furthermore, any provision found unenforceable may be partly enforced to the maximum extent enforceable under the law.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="7" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Force Majeure. Customer, Member, or Affiliate acknowledges that the Company is not liable for any damages or losses caused by the delay or inability to manufacture, sell, or deliver its products due to labor strikes, accidents, fire, flood, acts of civil authority, acts of God, acts of terrorists, or from any other causes that are beyond the control of the Company.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="8" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Governing Law, Arbitration Injunctive Relief. The State of Florida is the place of the origin of this&#xa0; 
        </li>
    </ol>
    <p style="margin-right:0.15pt">
        Contract and is where the Company accepted the offer of the Applicant to become a Customer,&#xa0; 
    </p>
    <p style="margin-right:0.15pt">
        Member, or Affiliate and where the Customer, Member, or Affiliate entered into the Contract with the&#xa0; 
    </p>
    <p style="margin-right:0.15pt">
        Company. The Contract is therefore to be construed in accordance with the laws of the State of 
    </p>
    <p style="margin-right:0.15pt">
        Florida (without giving effect to any conflict of law provision or rule) as to contracts made and to be wholly performed within the State. Any controversy or claim arising out of or relating to the Contract or the breach thereof, or any controversy or claim relating to the business relationships arising between Customers, Members, or Affiliates shall be resolved by mandatory, final, binding, nonappealable arbitration in Jupiter, Florida, United States of America.&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0.1pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="9" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:10.83pt; text-indent:0pt; padding-left:2.62pt">
            Attorneys Fees. If any suit, action, or proceeding is brought to enforce any term or provision of this Contract, the prevailing party shall be entitled to recover reasonable attorneys’ fees, costs, and expenses incurred, in addition to any other relief to which such party may be legally entitled.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="10" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.17pt; text-indent:0pt; padding-left:0.28pt">
            Successors and Assigns. The Contract will be legal and binding upon and inure to the benefit of the heirs, devisees, executors, administrators, personal representatives, successors, and assigns (as applicable) of the respective parties hereto.&#xa0; 
        </li>
    </ol>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <ol start="11" style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">
        <li style="margin-right:0.15pt; margin-left:13.45pt; text-indent:0pt">
            Limitation of Liability. To the extent permitted by law, the Company, its directors, officers, members, managers, shareholders, employees, assigns and agents (collectively referred to as&#xa0; 
        </li>
    </ol>
    <p style="margin-right:0.15pt">
        “Responsible Parties”) shall not be liable for, and the Customer, Member, or Affiliate releases Company and its Responsible Parties from and waive all claims, for any loss of profits, indirect, direct, special or consequential damages, and for any other losses incurred or suffered by Customers, Members, or Affiliates as a result of: (i) Customer, Member, or Affiliate’s breach of the&#xa0; 
    </p>
    <p style="margin-right:0.15pt">
        Contract, (ii) the promotion or operation of the Customers, Members, or Affiliateship and the Customers, Members, or Affiliateship Business; (iii) Customer, Member, or Affiliate’s incorrect or wrong data or information provided to the Company or its Responsible Parties; or (iv) the Customer, Member, or Affiliate’s failure to provide any information or data necessary for the Company to operate its business. EACH CUSTOMER, MEMBER, OR AFFILIATE AGREES THAT THE ENTIRE&#xa0; 
    </p>
    <p style="margin-right:0.15pt">
        LIABILITY OF THE COMPANY AND ITS RESPONSIBLE PARTIES FOR ANY CLAIM&#xa0; 
    </p>
    <p style="margin-right:0.15pt">
        WHATSOEVER RELATED TO THE CONTRACT, BUT NOT LIMITED TO, ANY CAUSE OF&#xa0; 
    </p>
    <p style="margin-right:10.75pt; margin-left:1pt; margin-bottom:0.25pt; text-indent:-1pt; text-align:justify; line-height:103%">
        ACTION SOUNDING IN CONTRACT, TORT, OR EQUITY, SHALL NOT EXCEED, AND SHALL BE LIMITED TO, THE AMOUNT OF PRODUCTS THE CUSTOMER, MEMBER, OR AFFILIATE HAS PURCHASED FROM THE COMPANY THAT ARE IN RESALABLE CONDITION.&#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>
    <p style="margin-left:0.75pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
        &#xa0; 
    </p>

    <h5>Mobile Messaging and SMS</h5>
    <p style="margin-bottom:14pt; line-height:normal">
        <span style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">TopShelfNutra uses mobile messaging and SMS services to communicate with customers. By providing your phone number, you agree to receive automated messages, including:
2-Factor Authentication (2FA) codes,
Password reset notifications,
Other account-related messages.
</span>
    </p>
    <h5>Consent and Opt-Out</h5>
    <p style="margin-bottom:14pt; line-height:normal">
        <span style="margin:0pt; padding-left:0pt; list-style-type:upper-latin">By checking the verification checkbox on our website, you explicitly consent to receive mobile messages from TopShelfNutra. You can opt-out of mobile messaging at any time by: Replying "STOP" to any message, or unchecking the checkbox in your account details.</span>
    </p>
`;

    return (
        <OffCanvasWrapper
            offCanvasPlacement="end"
            showOffCanvas={termsConditionsOffCanvasShow}
            setShowOffCanvas={setTermsConditionsOffCanvasShow}
            offCanvasTitle="Terms and Conditions"
            offCanvasBody={
                <section className="my-lg-14 my-8">
                    <div dangerouslySetInnerHTML={{ __html: data }} />
                </section>
            }
            fullWidth
        />
    );
};
