import react, { useEffect, useState } from "react";
import "./membership-info-off-canvas.scss";
import { OffCanvasWrapper } from "../OffCanvasWrapper/OffCanvasWrapper";
import { useAuthenticatedUser } from "../../context/AuthContext";

interface MembershipInfoOffCanvasProps {
    membershipInfoOffCanvasShow: boolean;
    setMembershipInfoOffCanvasShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MembershipInfoOffCanvas = ({ membershipInfoOffCanvasShow, setMembershipInfoOffCanvasShow }: MembershipInfoOffCanvasProps) => {
    const { authMember } = useAuthenticatedUser();

    return (
        <OffCanvasWrapper
            offCanvasPlacement="end"
            showOffCanvas={membershipInfoOffCanvasShow}
            setShowOffCanvas={setMembershipInfoOffCanvasShow}
            offCanvasTitle="Membership Pricing Help"
            fullWidth
            offCanvasBody={<div className="row mb-3 g-3 fs-5 mt-2">{`${!authMember?.active ? "Sign up for a membership and receive the following:" : ""} The first item of each product in every order is charged the member price; any additional units of the same product in the same order receive an even deeper discount.`}</div>}
        />
    );
};
