import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Check2, CheckCircleFill, CircleFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { AuthApi } from "../../apis/AuthApi";
import { BootstrapInput } from "../../components/BootstrapInput/BootstrapInput";
import { passMeetsRequirements } from "../../utils/string";
import "./sign-up-page.scss";
import { BootstrapButton } from "../../components/BootstrapButton/BootstrapButton";
import { TermsConditionsOffCanvas } from "../../components/TermsConditionsOffCanvas/TermsConditionsOffCanvas";
import { PrivacyPolicyOffCanvas } from "../../components/PrivacyPolicyOffCanvas/PrivacyPolicyOffCanvas";

interface SignUpPageProps {}

export interface SignupForm {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    how_heard: string;
    parent_display_id: string;
}

const defaultErrorMessage = "Please fill out all the fields below.";
const defaultSignUpForm = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    how_heard: "",
    parent_display_id: "",
};

export const SignUpPage = ({}: SignUpPageProps) => {
    const navigate = useNavigate();
    const [loadingSignup, setLoadingSignup] = useState(false);
    const [signUpForm, setSignUpForm] = useState<SignupForm>(defaultSignUpForm);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [emailValid, setEmailValid] = useState(true);
    const [firstNameValid, setFirstNameValid] = useState(true);
    const [lastNameValid, setLastNameValid] = useState(true);
    const [howHeardValid, setHowHeardValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);
    const [errorShow, setErrorShow] = useState(false);
    const [successfulSignup, setSuccessfulSignup] = useState(false);
    const signupHeaderRef = useRef<null | HTMLDivElement>(null);
    const [termsServicesOffCanvasShow, setTermsConditionsOffCanvasShow] = useState(false);
    const [privacyPolicyOffCanvasShow, setPrivacyPolicyOffCanvasShow] = useState(false);
    const [toggleResendEmailVerificationEmail, setToggleResendVerificationEmail] = useState(false);
    const [invalidAffiliateLink, setInvalidAffiliateLink] = useState(false);

    const scrollToSignup = () => {
        signupHeaderRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        handleVerifyAffiliateLink();
    }, []);

    const handleVerifyAffiliateLink = () => {
        const display_id = localStorage.getItem("affiliate");
        const currentUrl = window.location;
        let path = currentUrl.pathname;
        path = path.replace("/account/signup", "");
        path = path.replace("/account/signup/", "");
        path = path.replace("/", "");
        setErrorShow(false);

        if (display_id || path.length > 6) {
            if (path.length > 6) {
                setErrorMessage(`Note - '${path}' is not a valid afilliate link.`);
                setErrorShow(true);
                setInvalidAffiliateLink(true);
            } else {
                display_id &&
                    AuthApi.VerifyParentDisplayId(display_id)
                        .then((response) => {
                            if (response.success) {
                                setSignUpForm({ ...signUpForm, how_heard: response.parent_name });
                            } else {
                                setErrorMessage(`Note - '${display_id}' is not a valid afilliate link.`);
                                setSignUpForm({ ...signUpForm, how_heard: "" });
                                setErrorShow(true);
                                setInvalidAffiliateLink(true);
                            }
                        })
                        .catch(console.error);
            }
        }
    };

    const handleSignUpClick = async () => {
        const first_name = signUpForm.first_name.length > 0;
        const last_name = signUpForm.last_name.length > 0;
        const how_heard = signUpForm.how_heard.length > 0;
        const email = signUpForm.email.length > 0 && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(signUpForm.email);
        const password = signUpForm.password.length > 0 && signUpForm.password === confirmPassword && passMeetsRequirements(signUpForm.password);
        const parent_id = localStorage.getItem("affiliate");
        setErrorShow(false);
        setEmailValid(email);
        setFirstNameValid(first_name);
        setLastNameValid(last_name);
        setHowHeardValid(how_heard);
        setPasswordValid(password);

        if (first_name && last_name && email && password && how_heard) {
            let copyOfSignUpForm = { ...signUpForm };
            copyOfSignUpForm.email = copyOfSignUpForm.email.toLowerCase();
            if (parent_id !== null) {
                copyOfSignUpForm.parent_display_id = parent_id;
            }
            let response = await AuthApi.Register(copyOfSignUpForm).catch(console.error);

            if (!response.success) {
                setEmailValid(false);
                setErrorMessage("The email you entered is already in our system.");
                setErrorShow(true);
                setLoadingSignup(false);
                return;
            } else {
                localStorage.removeItem("affiliate");
                setSuccessfulSignup(true);
            }
        } else {
            if (!first_name || !last_name || !email || !how_heard || !password) {
                if (!first_name || !last_name || !how_heard) {
                    setErrorMessage(defaultErrorMessage);
                } else if (!email) {
                    setErrorMessage("Please use a well formatted email address.");
                } else {
                    setErrorMessage("Password fields don't meet requirements or aren't a match.");
                }
            }
            setErrorShow(true);
        }
        setLoadingSignup(false);
    };

    const handleReturnToDashboard = () => {
        setSignUpForm(defaultSignUpForm);
        navigate("/");
    };

    useEffect(() => {
        if (toggleResendEmailVerificationEmail) {
            setTimeout(() => {
                setToggleResendVerificationEmail(false);
            }, 5000);
        }
    }, [toggleResendEmailVerificationEmail]);

    const handleResendVerificationEmailClick = async () => {
        let response = await AuthApi.ResendVerificationEmail(signUpForm.email).catch(console.error);
        if (response.success) {
            setToggleResendVerificationEmail(true);
        }
    };

    const handleKeyDownSignUp = (e: any) => {
        if (e.key === "Enter") {
            e.preventDefault();
            setLoadingSignup(true);
            handleSignUpClick();
        }
    };

    return (
        <>
            <TermsConditionsOffCanvas
                termsConditionsOffCanvasShow={termsServicesOffCanvasShow}
                setTermsConditionsOffCanvasShow={setTermsConditionsOffCanvasShow}
            />
            <PrivacyPolicyOffCanvas
                privacyPolicyOffCanvasShow={privacyPolicyOffCanvasShow}
                setPrivacyPolicyOffCanvasShow={setPrivacyPolicyOffCanvasShow}
            />
            <div id="wrapperMainContainer">
                <section className="mt-4">
                    <div className="container sign-up-container">
                        <div className="row justify-content-center align-items-top flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column">
                            <div className="col-12 col-lg-5 col-md-12 col-lg-4 order-lg-1 order-2">
                                <div className="mb-lg-9 mb-5">
                                    <div className="mb-1 h2 fw-bold">Signup Process</div>
                                </div>

                                <div className="d-flex flex-column">
                                    <div className="d-flex mb-4">
                                        <div className="fs-1">
                                            <CheckCircleFill className="tsn-green" />
                                        </div>
                                        <div className="ms-3 align-self-start">
                                            <div className="fs-5 mb-2 fw-bold">Step One</div>
                                            <p>
                                                Create an account by completing the signup form. Click{" "}
                                                <span onClick={scrollToSignup} className="fw-bold btn-jump-to-register" role="button">
                                                    Register
                                                </span>{" "}
                                                to start the process.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="d-flex mb-4">
                                        <div className="fs-1">
                                            <CheckCircleFill className="tsn-green" />
                                        </div>
                                        <div className="ms-3 align-self-start">
                                            <div className="fs-5 mb-2  fw-bold">Step Two</div>
                                            <p>
                                                We will send you an email with a verification link that will direct you back to our site to verify
                                                your email.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="d-flex mb-4">
                                        <div className="fs-1">
                                            <CheckCircleFill className="tsn-green" />
                                        </div>
                                        <div className="ms-3 align-self-start">
                                            <div className="fs-5 mb-2  fw-bold">Step Three</div>
                                            <p>Sign-in to your account and setup your billing information.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-5 col-md-12 offset-lg-1 col-lg-4 order-lg-2 order-2">
                                {!successfulSignup ? (
                                    <div className="wrapper-signup-form mb-5">
                                        <div className="mb-lg-4 mb-5">
                                            <div ref={signupHeaderRef} className="mb-1 h2 fw-bold">
                                                Signup Form
                                            </div>
                                        </div>
                                        <div className={`my-4 text-center ${errorShow ? "tsn-red" : "tsn-white"}`}>{errorMessage}</div>
                                        <div className="row mb-3 g-3">
                                            <div className="col-12 container-how-heard">
                                                <BootstrapInput
                                                    value={signUpForm.how_heard}
                                                    onKeyDown={handleKeyDownSignUp}
                                                    onChange={(e) => setSignUpForm({ ...signUpForm, how_heard: e.target.value })}
                                                    label="Let us know who referred you"
                                                    valid={howHeardValid}
                                                    autoFocus
                                                />
                                            </div>

                                            <div className="col-6">
                                                <BootstrapInput
                                                    value={signUpForm.first_name}
                                                    onKeyDown={handleKeyDownSignUp}
                                                    onChange={(e) => setSignUpForm({ ...signUpForm, first_name: e.target.value })}
                                                    label="First name"
                                                    valid={firstNameValid}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <BootstrapInput
                                                    value={signUpForm.last_name}
                                                    onKeyDown={handleKeyDownSignUp}
                                                    onChange={(e) => setSignUpForm({ ...signUpForm, last_name: e.target.value })}
                                                    label="Last name"
                                                    valid={lastNameValid}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <BootstrapInput
                                                    value={signUpForm.email}
                                                    onKeyDown={handleKeyDownSignUp}
                                                    onChange={(e) => setSignUpForm({ ...signUpForm, email: e.target.value })}
                                                    label="Email"
                                                    valid={emailValid}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <div className="password-field position-relative">
                                                    <BootstrapInput
                                                        value={signUpForm.password}
                                                        onKeyDown={handleKeyDownSignUp}
                                                        onChange={(e) => setSignUpForm({ ...signUpForm, password: e.target.value })}
                                                        label="Password"
                                                        valid={passwordValid}
                                                        type="password"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <BootstrapInput
                                                    value={confirmPassword}
                                                    onKeyDown={handleKeyDownSignUp}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    label="Confirm Password"
                                                    valid={passwordValid}
                                                    type="password"
                                                />
                                            </div>
                                            <div className="col-12 mt-2 me-4 mb-4 text-center">
                                                <div className="fs-6 fw-lighter ">
                                                    {" "}
                                                    <span className="fw-bold">Password requirements:</span> minimum 8 characters, at least one
                                                    uppercase letter, one number, and one special character.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-grid my-9">
                                            <div className="col-12 d-grid">
                                                <BootstrapButton
                                                    disabled={invalidAffiliateLink}
                                                    variant="secondary"
                                                    title="Register"
                                                    loading={loadingSignup}
                                                    onClick={() => {
                                                        setLoadingSignup(true);
                                                        handleSignUpClick();
                                                    }}
                                                />
                                            </div>
                                            <p className="text-center">
                                                <small>
                                                    By continuing, you agree to our
                                                    <a onClick={() => setTermsConditionsOffCanvasShow(true)} className="text-reset cursor-pointer">
                                                        {" "}
                                                        Terms of Service
                                                    </a>{" "}
                                                    &amp;{" "}
                                                    <a onClick={() => setPrivacyPolicyOffCanvasShow(true)} className="text-reset cursor-pointer">
                                                        Privacy Policy
                                                    </a>
                                                    .
                                                </small>
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={`wrapper-signup-complete mb-5`}>
                                        <div className="mb-lg-9 mb-5">
                                            <div className="mb-1 h2 fw-bold">Signup Successful</div>
                                        </div>

                                        <div className="mb-lg-9 mb-5">
                                            <div className="mb-1 fs-4">
                                                An email has been sent to <b>{signUpForm.email}</b> for account verification. Please note, your email could take up to <b>5 minutes</b> to show up in your inbox.
                                            </div>
                                            <div className="mt-4 text-center">
                                                Didn't get your Verification Email? Click here:{" "}
                                                <span
                                                    onClick={handleResendVerificationEmailClick}
                                                    className={`mt-2 text-center ${
                                                        toggleResendEmailVerificationEmail ? "sent-auth-text" : "resend-auth-text"
                                                    }`}
                                                >
                                                    {toggleResendEmailVerificationEmail
                                                        ? `Authorization Code sent to ${signUpForm.email}.`
                                                        : "Resend Verification Email."}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="mb-lg-9 mb-5">
                                            <div className="mb-1 fs-4">
                                                Once your account is verified you can sign-in to your account and manage things like:
                                                <ul className="mt-2">
                                                    <li>preluanch subscription for access to discounted product pricing</li>
                                                    <li>default credit card for purchases and subscription</li>
                                                    <li>build affiliate links and track affiliate family</li>
                                                </ul>
                                            </div>
                                            <div className="mt-3">
                                                <div className="col-12 d-grid">
                                                    <BootstrapButton onClick={handleReturnToDashboard} title="Return to Dashboard" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};
